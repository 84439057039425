import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { ScreenInfoLayout } from './_layout';

import Logo from '../../../assets/images/logo/icon.svg';

export function ScreenInfoLoading() {
  return (
    <ScreenInfoLayout>
      <Box
        sx={{
          m: 1,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '60px',
          height: '60px',
        }}
      >
        <img src={Logo} alt="logo" style={{ width: '30px' }} />
        <CircularProgress
          size={68}
          sx={{
            position: 'absolute',
            zIndex: 1,
          }}
        />
      </Box>
    </ScreenInfoLayout>
  );
}
