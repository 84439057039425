import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { ScreenInfoLayout } from './_layout';

export function ScreenInfoError({ resetErrorBoundary }: any) {
  return (
    <ScreenInfoLayout>
      <Paper variant="outlined" sx={{ p: 3 }}>
        <Typography variant="h4" sx={{ fontWeight: 500 }} gutterBottom>
          Error
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Something went wrong.
        </Typography>
        <Button variant="outlined" onClick={resetErrorBoundary} fullWidth>
          Try Again
        </Button>
        <Button
          variant="contained"
          disableElevation
          sx={{ mt: 2 }}
          fullWidth
          onClick={() => window.location.reload()}
        >
          Reload Page
        </Button>
      </Paper>
    </ScreenInfoLayout>
  );
}

export function ErrorFallback({ error, resetErrorBoundary }: any) {
  console.log(error);
  return <ScreenInfoError resetErrorBoundary={resetErrorBoundary} />;
}
