import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import '@fontsource/inter/latin.css';
import '@fontsource/baskervville/latin.css';

const interFont = ['Inter', 'serif'].join(',');
const baskervvilleFont = ['Baskervville', 'serif'].join(',');

const theme = createTheme({
  palette: {
    primary: {
      main: '#00BBF9',
    },
    secondary: {
      main: '#F15BB5',
    },
  },
  typography: {
    fontFamily: interFont,
    h1: {
      fontFamily: interFont,
      fontWeight: 900,
    },
    h2: {
      fontFamily: interFont,
      fontWeight: 700,
    },
    h3: {
      fontFamily: interFont,
      fontWeight: 700,
    },
    h4: {
      fontFamily: interFont,
      fontWeight: 500,
    },
    h5: {
      fontFamily: interFont,
    },
    h6: {
      fontFamily: interFont,
    },
  },
});

export function ThemeProvider({ children }: any) {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
