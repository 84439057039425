import { CommonProviders } from '@taxonomy.cc/core/src/_lib/providers/common';

import { PagesRoutes } from './pages/_routes';

const firebaseConfig = {
  appId: '1:738539520774:web:6947ae5bf9ececbc6ed487',
  measurementId: 'G-E7GXHYM69N',
};

export function App() {
  return (
    <CommonProviders firebaseConfig={firebaseConfig}>
      <PagesRoutes />
    </CommonProviders>
  );
}

export default App;
