import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';

import { LayoutPrivacy } from '../../layout/_copyright';

import Logo from '../../../assets/images/logo/icon-text-blue.svg';
import Bg from '../../../assets/images/bg/lance-asper-3P3NHLZGCp8-unsplash.jpg';

import '../../../assets/css/_full-height.css';

export function ScreenInfoComingSoon() {
  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        py: 5,
      }}
    >
      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <img
          src={Logo}
          alt="logo"
          style={{ maxWidth: '100%', width: '270px' }}
        />

        <Card
          sx={{
            mt: 4,
            mb: 6,
            py: 10,
            px: 2,
            backgroundImage: `url(${Bg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          variant="outlined"
        >
          <Typography
            variant="h3"
            color="secondary"
            sx={{
              fontWeight: 900,
              wordSpacing: 0.1,
              WebkitTextStroke: '1px #fff',
            }}
          >
            Coming Soon
          </Typography>
        </Card>

        <LayoutPrivacy />
      </Box>
    </Container>
  );
}
