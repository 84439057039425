import { Link as RouterLink } from 'react-router-dom';

import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

export function ScreenInfoNotFound() {
  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="xs" sx={{ py: 5, textAlign: 'center' }}>
        <SignalCellularConnectedNoInternet0BarIcon sx={{ fontSize: 49 }} />
        <Typography variant="h3">404</Typography>
        <Typography variant="h4" gutterBottom>
          Not Found
        </Typography>
        <Typography variant="body1" paragraph>
          We could not find the page you are looking for.
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to="/"
          disableElevation
          sx={{ textTransform: 'none' }}
          startIcon={<HomeOutlinedIcon />}
        >
          Go Home
        </Button>
      </Container>
    </Box>
  );
}
