import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirebaseApp,
  AnalyticsProvider,
  AuthProvider,
  useInitPerformance,
  AppCheckProvider,
} from 'reactfire';

import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyAawRwmr-s-ezgSMAzGEPUoUDuCwNHE3Sk',
  authDomain: 'accounts.taxonomy.cc',
  projectId: 'taxonomy-ebf4a',
  storageBucket: 'taxonomy-ebf4a.appspot.com',
  messagingSenderId: '738539520774',
  appId: '1:738539520774:web:0bd183b343d0af246ed487',
  measurementId: 'G-EGPKFJEFN5',
};

const APP_CHECK_TOKEN = '6LdpJ6seAAAAAIQcuOYRpxa4uZDcTsfrrQB9IGtP';

function FirebaseServicesProviders({ children }: any) {
  const app = useFirebaseApp();

  const auth = getAuth(app);
  const firestoreInstance = getFirestore(app);
  const analytics = getAnalytics(app);

  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });

  return (
    <AppCheckProvider sdk={appCheck}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestoreInstance}>
          <AnalyticsProvider sdk={analytics}>{children}</AnalyticsProvider>
        </FirestoreProvider>
      </AuthProvider>
    </AppCheckProvider>
  );
}

export function FirebaseProvider({ config, children }: any) {
  return (
    <FirebaseAppProvider firebaseConfig={{ ...firebaseConfig, ...config }}>
      <FirebaseServicesProviders>{children}</FirebaseServicesProviders>
    </FirebaseAppProvider>
  );
}

// Firebase Effects
export function FirebaseEffects() {
  const location = useLocation();

  const app = useFirebaseApp();
  const analytics = getAnalytics(app);

  // ------------------------------------------------------------
  // Emulators
  // Point Firestore + Auth to localhost if localhost
  React.useEffect(() => {
    if (window.location.hostname === 'localhost') {
      try {
        const db = getFirestore();
        connectFirestoreEmulator(db, 'localhost', 8080);
        const auth = getAuth();
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  // ------------------------------------------------------------
  // Analytics
  // Log analytics events
  React.useEffect(() => {
    const pagePath = location.pathname + location.search;
    setCurrentScreen(analytics, pagePath);
    logEvent(analytics, 'page_view', { pagePath });
  }, [location, analytics]);

  return null;
}
