import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export function LayoutPrivacy() {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{
        '& a': {
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
        },
      }}
    >
      <Link component={RouterLink} to="/privacy-policy" target="_blank">
        Privacy Policy
      </Link>
      {' . '}
      <Link component={RouterLink} to="/terms-of-service" target="_blank">
        Terms of Service
      </Link>
    </Typography>
  );
}

export function LayoutCopyright() {
  return (
    <>
      {/* Privacy + Terms + Copyright */}
      <Box sx={{ mt: 5, mb: 3 }}>
        <LayoutPrivacy />
      </Box>
      <Box mt={2} mb={2}>
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} Tenery Research
        </Typography>
      </Box>
    </>
  );
}
