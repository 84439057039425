import Container from '@mui/material/Container';

import '../../../assets/css/_full-height.css';

export function ScreenInfoLayout({ children }: any) {
  return (
    <Container
      maxWidth="xs"
      sx={{
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {children}
    </Container>
  );
}
