import { Route, Routes } from 'react-router-dom';

import { RedirectRoute } from '@taxonomy.cc/core/src/_lib/route-utils';
import { ScreenInfoComingSoon } from '@taxonomy.cc/core/src/_screen/_info/coming-soon';
import { ScreenInfoNotFound } from '@taxonomy.cc/core/src/_screen/_info/not-found';

export function PagesRoutes() {
  return (
    <Routes>
      {/* impressum */}
      {/* privacy policy */}
      <Route
        path="/privacy-policy"
        element={
          <RedirectRoute
            url="https://bit.ly/384ST6f"
            title="Privacy Policy - Taxonomy"
          />
        }
      />
      {/* terms of service */}
      <Route
        path="/terms-of-service"
        element={
          <RedirectRoute
            url="https://bit.ly/3jQUcIt "
            title="Terms of Service - Taxonomy"
          />
        }
      />

      {/* routes */}
      <Route path="/" element={<ScreenInfoComingSoon />} />
      <Route path="*" element={<ScreenInfoNotFound />} />
    </Routes>
  );
}
